$(function () {
    /// LOGIN ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    var $user = $("#username");
    var $pass = $("#password");

    if ($user.val().length == 0) {
        $user.select().focus();
    } else {
        $pass.select().focus();
    }


    $('.owl-carousel').owlCarousel({
        autoplay: true,
        loop: true,
        dots: true,
        items: 1,
        startPosition: 0,
        autoplayTimeout: 10000, //temps entre chaque slide
        smartSpeed: 500, //speed de transition entre les slide
    })

    $('.current_lang').addClass('myLang');
    $('#lang_switcher').append('<span class="sliding_selector"></span>')
    $('#lang_switcher').css('border', '3px solid #14b9d5')
    $('.sliding_selector').animate({
        left: $('.myLang').position().left
    }, 500);

    $('.lang').hover(function () {
        $('.sliding_selector').animate({
            left: $(this).position().left
        }, 300);
    });

    $('#lang_switcher').mouseleave(function () {
        $('.sliding_selector').stop().animate({
            left: $('.myLang').position().left
        }, 500);
    });


    $("#username, #password").on('change paste keyup blur', function () {

        if ($user.val().length == 0 && $pass.val().length == 0) {
            $pass.css('border-bottom-color', 'white')
            $user.css('border-bottom-color', 'white')
        } else if ($user.val().length == 0) {

            if ($pass.val().length != 0) {

                $user.css('border-bottom-color', 'red')
            } else {

                $user.css('border-bottom-color', 'white')
            }
        } else if ($pass.val().length == 0) {

            if ($user.val().length != 0) {

                $pass.css('border-bottom-color', 'red')
            }
        } else {
            $pass.css('border-bottom-color', 'white')
            $user.css('border-bottom-color', 'white')
        }
    });
});
